<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2022-01-13 11:52 -->
<template>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a
        class="nav-link"
        aria-current="page"
        href="javascript:void(0)"
        :class="{ active: employee_id == null }"
        @click="makeActions({ id: null })"
        >Todos</a
      >
    </li>
    <li class="nav-item" v-for="l in options" :key="l.id">
      <a
        class="nav-link"
        href="javascript:void(0)"
        :class="{ active: employee_id == l.id }"
        @click="makeActions(l)"
        >{{ l.name }}</a
      >
    </li>
  </ul>
</template>
<script>
import { AdminService } from "../../lt/admin-module/AdminService.js";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    specialties: {},
    employee_id: {}
  },

  data: () => ({
    options: []
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    AdminService.getEmployees({
      specialties: this.specialties
    }).then((res) => {
      this.options = res.data;
    });
  },

  methods: {
    makeActions(employee) {
      this.$emit("change", employee.id);
      let queryParams = {
        ...this.$route.query
      };
      queryParams["employee_id"] = employee.id;
      this.$router.replace({
        query: queryParams
      });
    }
  }
};
</script>

<style scoped></style>
