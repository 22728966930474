<template>
  <div
    class=" rounded p-1 px-2  btn-block text-nowrap text-white"
    :class="{
      'bg-success': planStatus == 2 || planStatus == 3,
      'bg-danger': planStatus == 1,
      'bg-warning': planStatus == 4,
      'bg-info': planStatus == 0
    }"
  >
    {{ text }}
  </div>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    planStatus: {
      default: 0
    }
  },

  data: () => ({
    //
  }),

  computed: {
    text() {
      switch (this.planStatus) {
        case 1:
          return "❕ Deudas";
        case 2:
          return "✓ hay saldo";
        case 3:
          return "🗈 Diagnostico";
        case 4:
          return "💸 Sin saldo";
        default:
          return "😐 Sin Historia";
      }
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
