<template>
  <div class="dropdown-menu">
    <router-link tag="a" class="dropdown-item" :to="pathToCreate(1)"
      >Proforma Odontologia
    </router-link>
    <router-link tag="a" class="dropdown-item" :to="pathToCreate(2)"
      >Proforma Pediatria
    </router-link>
    <router-link tag="a" class="dropdown-item" :to="pathToCreate(3)"
      >Proforma Implantes
    </router-link>
    <router-link tag="a" class="dropdown-item" :to="pathToCreate(4)"
      >Prospecto Ortodoncia
    </router-link>
  </div>
</template>
<script>
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    clientId: {}
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    pathToCreate(group) {
      return (
        `/dental-clinic/proform?new=1&g=${group}` +
        (this.clientId ? `&clientId=${this.clientId}` : "")
      );
    }
  }
};
</script>

<style></style>
