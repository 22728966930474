<template>
  <div class="app_buttons_new">
    <div class="text-center" v-show="showButtons">
      <template v-for="(l, i) in proforms">
        <router-link
          :key="`1${i}`"
          :to="{ path: `/dental-clinic/proform`, query: { new: 1, g: i + 1 } }"
          class="d-inline btn btn-success btn-circle my-2 shadow"
          data-toggle="tooltip"
          data-placement="left"
          data-trigger="manual"
          :title="`Proforma ${l}`"
          v-tooltip="showButtons"
        >
          <i class="fas fa-clipboard-list"></i>
        </router-link>
        <br :key="`2${i}`" class="my-2" />
      </template>
      <button
        @click="$emit('newClient')"
        class="d-inline btn btn-success btn-circle my-2 shadow"
        data-toggle="tooltip"
        data-placement="left"
        data-trigger="manual"
        title="Paciente"
        v-tooltip="showButtons"
      >
        <i class="fas fa-user"></i>
      </button>
    </div>

    <button
      href="#"
      class="btn btn-success btn-circle btn-lg shadow-lg p-3"
      @click="showButtons = !showButtons"
      @blur="retardedBlur()"
    >
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>
<script>
import $ from "jquery";

const showDirective = {
  update: function(el, { value }) {
    if (value) $(el).tooltip("show");
    else $(el).tooltip("hide");
  }
};

export default {
  components: {
    //
  },

  directives: {
    tooltip: showDirective
  },

  // filters

  props: {
    //
  },

  data: () => ({
    showButtons: false,
    proforms: ["Odontologia", "Pediatria", "Implantes", "Ortodoncia"]
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  beforeDestroy() {
    this.showButtons = false;
    $('[role="tooltip"]').hide();
  },

  methods: {
    retardedBlur() {
      setTimeout(() => {
        this.showButtons = false;
      }, 100);
    }
  }
};
</script>

<style scoped>
.app_buttons_new {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;
}
</style>
