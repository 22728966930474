<template>
  <div>
    <div class="d-flex mb-2">
      <div :style="`background-color: white`" class="mr-2">&nbsp;</div>
      <app-checkbox v-model="all">Todos</app-checkbox>
    </div>
    <div v-for="(l, i) in list" :key="i" class="d-flex mb-2">
      <div :style="`background-color: ${l.color}`" class="mr-2">&nbsp;</div>
      <app-checkbox v-model="states[i]" @input="emitValues()">{{
        l.name
      }}</app-checkbox>
    </div>
    <slot></slot>
  </div>
</template>
<script>
import { mapState } from "vuex";

// export function gsQuoteStatus(s) {
//   return function(s) { return s.config.service.quotes.status_list }
// }

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    all: false,
    states: {}
  }),

  computed: {
    ...mapState({
      list: s => s.config.service.quotes.status_list
    })
  },

  watch: {
    all(val) {
      // console.log("como");
      this.setAllValues(val);
      this.emitValues();
    }
  },

  created() {
    for (let it in this.list) {
      this.states[it] = this.list[it].show;
    }
  },

  mounted() {
    this.$emit("mounted");
  },

  methods: {
    setAll() {
      this.all = true;
    },
    setAllValues(val) {
      for (let i in this.list) {
        // console.log("Gaaa");
        this.states[i] = val;
      }
    },
    getStates() {
      return {
        // all: this,all,
        ...this.states
      };
    },
    emitValues() {
      this.$emit("input", this.getStates());
    }
  }
};
</script>

<style></style>
